import { createGlobalStyle } from "styled-components"
import theme, { darkColors } from "../../config/theme"
import { normalize } from "polished"

export const GlobalStyle = createGlobalStyle`

${normalize()}

html {
  font-size: 100%;
  line-height: 1;
}

body {
  // Colors
  color: ${props =>
    props.activeTheme === "dark" ? darkColors.fg : theme.colors.fg};
  background-color: ${props =>
    props.activeTheme === "dark" ? "#000" : theme.colors.bg};

  // Typography
  font-size: 1rem;
  // font-size: calc(.75vmin + .75em);
  font-family: ${theme.fonts.sans};
  font-weight: ${theme.fontWeights.body};
  font-kerning: normal;
  font-feature-settings: "kern", "liga", "clig", "calt";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Others
  margin: 0;
  padding: 16px; // in px to make full-screen element negative margin easier
}

${"" /* https://css-tricks.com/box-sizing/ */}
*, *:before, *:after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: ${theme.fonts.heading};
  font-weight: ${theme.fontWeights.head};
}

cite {
  font-style: normal;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
}

blockquote,
h1, h2, h3, h4, h5, h6,
ul, ol {
  margin: 0;
  padding: 0;
}

p {
  margin-top: 0;
}

a {
  color: currentColor;
}

svg {
  fill: currentColor;
}

#root > * > * > * {
  width: 100%;
}

.show-on-focus {
  height: 1px;
  position: absolute;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  margin: 0px;
  &:focus {
    clip: auto;
    height: auto;
    width: auto;
    z-index: 20;
  }
}
`
