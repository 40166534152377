import styled from "styled-components"
import { space, fontSize } from "styled-system"
import theme from "../../../config/theme"
import { outerContainer, inlineList } from "../../../config/mixins"
import { mq } from "../../../config/mq"

///

export const FooterWrapper = styled.footer`
  ${fontSize}
  ${space}
  ${outerContainer()}

  ${
    "" /* max-width: calc(${
    theme.containerMaxWidth
  } + 8em); // wider than content container */
  }
`
FooterWrapper.defaultProps = {
  fontSize: 0,
  mt: [3, 3, 4, 5],
}

///

export const Copyright = styled.small`
  ${fontSize}

  display: block;
  opacity: 0.75;
`
Copyright.defaultProps = {
  fontSize: 0,
}

///

export const FooterNavContainer = styled.div`
  ${space}

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 0.125rem solid ${theme.colors.fg};
  line-height: ${theme.lineHeights[1]};
  font-size: 1.1em;

  ${mq.sm(`
    flex-direction: row;
  `)}

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      color: ${theme.colors.accent};
      text-decoration: underline;
    }
  }

  ul {
    ${inlineList("left")}

    margin-bottom: 1rem;

    ${mq.sm(`
      margin-bottom: 0;
    `)}
  }
`
FooterNavContainer.defaultProps = {
  py: 3,
}

///

export const FooterListItem = styled.li`
  text-transform: uppercase;

  &:not(:last-child):after {
    content: "/";
    margin-left: 0.5em;
    margin-right: 0.25em;
    opacity: 0.5;
  }
`

///

export const SocialLink = styled.a.attrs({
  rel: "noopener external",
  target: "_blank",
})``
