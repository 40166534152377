import styled from "styled-components"
import { fontSize, fontFamily, space } from "styled-system"
import { outerContainer, inlineList } from "../../../config/mixins"
import theme from "../../../config/theme"
import { transparentize } from "polished"
// import { mq } from '../../../config/mq'

///

export const HeaderWrapper = styled.header`
  ${space}
  ${outerContainer()}

  text-align: center;

  h1 {
    display: inline-block;
  }

  ul {
    ${inlineList()}
  }

  a {
    text-decoration: none;
    &:hover,
    &:focus {
      color: ${theme.colors.accent};
    }
  }
`
HeaderWrapper.defaultProps = {
  pt: [3, 3, 4],
  pb: [3],
}

///

export const SiteTitleBase = styled.span`
  ${fontSize}
  ${fontFamily}
  ${space}

  display: block;
  text-transform: uppercase;
`

///

export const SiteTitleLarge = styled(SiteTitleBase)`
  font-weight: 500;
`
SiteTitleLarge.defaultProps = {
  fontSize: [12, 13, 14, 16],
  mt: [-1, -1, -2],
}

///

export const SiteTitleSmall = styled(SiteTitleBase)`
  font-weight: 400;
`
SiteTitleSmall.defaultProps = {
  fontSize: [6, 7, 8, 9],
  fontFamily: "sans",
}

///

export const HeaderNav = styled.ul`
  ${space}

  a {
    &:hover,
    &:focus {
      text-decoration: underline;
      text-decoration-color: ${transparentize(0.75, theme.colors.accent)};
    }
  }
`
HeaderNav.defaultProps = {
  mt: 3,
  mb: [3, 3, 4],
}

///

export const HeaderListItem = styled.li`
  ${fontSize}
  ${space}

  &.social-icon {
    margin-left: 0.25em;
    margin-right: 0.25em;
  }

  .is-active {
    color: ${theme.colors.accent};
    border-bottom: solid 0.125rem;
  }
`
HeaderListItem.defaultProps = {
  fontSize: [3, 5, 6],
}

///

export const SocialLink = styled.a.attrs({
  rel: "noopener external",
  target: "_blank",
})`
  display: inline-block;
  padding: 0.5rem;
`

///

export const SocialIcon = styled.svg.attrs({
  role: "presentation",
  ariaHidden: true,
  xmlns: "http://www.w3.org/2000/svg",
  width: 24, // fallback
  height: 24,
})`
  width: 1.5rem;
  height: 1.5rem;
`
