/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// Prepare structured data objects
let schema
const baseSchema = {
  "@context": "http://schema.org",
}
const websiteSchema = {
  "@type": "WebSite",
  name: "Comics by Azam Raharjo",
  url: "",
  thumbnailUrl: "",
  author: {},
}
const personSchema = {
  "@type": "Person",
  name: "Azam Raharjo",
  description: "Comic artist and illustrator",
  url: "",
  image: "",
  sameAs: [],
}
const comicIndexSchema = {
  "@type": "ComicSeries",
  name: "",
  description: "",
  datePublished: "",
  url: "",
  thumbnailUrl: "",
}
const comicPostSchema = {
  "@type": "ComicIssue",
  // same as comicIndexSchema
  hasPart: {
    "@type": "ComicStory",
    name: "",
    author: {},
    artist: {},
  },
}

function SEO({ description, lang, meta, title, url, image, pubDateTime, isArticle, comicType }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            siteLogo
            social {
              twitter
              array
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const defaultImg = `${site.siteMetadata.siteUrl}/${site.siteMetadata.siteLogo}`

  let miscMetaOg = []
  if (isArticle) {
    miscMetaOg = [
      {
        name: `article:author`,
        content: `Azam Raharjo`,
      },
      {
        name: `og:image:width`,
        content: `512`,
      },
      {
        name: `og:image:height`,
        content: `256`,
      },
    ]
    if (pubDateTime) {
      miscMetaOg[1] = {
        name: `article:published_time`,
        content: pubDateTime,
      }
    }
  }

  /**
   * Populate JSON-LD structured data
   */
  personSchema.url = site.siteMetadata.siteUrl
  personSchema.image = `${site.siteMetadata.siteUrl}/azam-bio.jpg`
  personSchema.sameAs = site.siteMetadata.social.array

  websiteSchema.url = site.siteMetadata.siteUrl
  websiteSchema.thumbnailUrl = defaultImg
  websiteSchema.author = personSchema

  if (isArticle) {
    if (comicType === "index") {
      comicIndexSchema.name = title
      comicIndexSchema.description = description
      comicIndexSchema.datePublished = pubDateTime
      comicIndexSchema.url = url
      comicIndexSchema.thumbnailUrl = image
      comicIndexSchema.author = personSchema
      schema = {
        ...baseSchema,
        ...comicIndexSchema,
      }
    }
    if (comicType === "post") {
      comicPostSchema.name = title
      comicPostSchema.datePublished = pubDateTime
      comicPostSchema.url = url
      comicPostSchema.thumbnailUrl = image
      comicPostSchema.hasPart.name = title
      comicPostSchema.hasPart.description = description
      comicPostSchema.hasPart.author = personSchema
      comicPostSchema.hasPart.artist = personSchema
      schema = {
        ...baseSchema,
        ...comicPostSchema,
      }
    }
  } else {
    schema = {
      ...baseSchema,
      ...websiteSchema,
    }
  }
  // end JSON-LD data

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title || site.siteMetadata.title}
      titleTemplate={title ? `%s | ${site.siteMetadata.title}` : `%s`}
      meta={[
        { 
          name: 'google-site-verification', 
          content: 'D9WjPQeJrsgcKfbiwf8h_8asFGnnZQwb537eS2d60Pc' 
        },
        /**
         * Title
         * (OpenGraph, Twitter)
         */
        {
          property: `og:title`,
          content: title ? `${title} | ${site.siteMetadata.title}` : `${site.siteMetadata.title}`,
        },
        {
          name: `twitter:title`,
          content: title ? `${title} | ${site.siteMetadata.title}` : `${site.siteMetadata.title}`,
        },

        /**
         * Description
         * (HTML meta, OpenGraph, Twitter)
         */
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },

        /**
         * Image
         * (OpenGraph, Twitter)
         */
        {
          property: `og:image`,
          content: image || defaultImg,
        },
        {
          name: `twitter:image`,
          content: image || defaultImg,
        },

        /**
         * Other details
         * (OpenGraph)
         */
        {
          property: `og:url`,
          content: url || site.siteMetadata.siteUrl,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:locale`,
          content: lang,
        },
        {
          property: `og:type`,
          content: isArticle ? "article" : "website",
        },
        ...miscMetaOg,

        /**
         * Other details
         * (Twitter)
         */
        {
          name: `twitter:card`,
          content: isArticle ? "summary_large_image" : "summary",
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.social.twitter,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.social.twitter,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  isArticle: false,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  pubDateTime: PropTypes.string,
  isArticle: PropTypes.bool,
  comicType: PropTypes.oneOf(["index", "post"]),
}

export default SEO
