const nav = {
  header: [
    {
      label: "Home",
      slug: "/",
    },
    {
      label: "Artwork",
      slug: "https://azamraharjo.com",
    },
    {
      label: "About",
      slug: "/about",
    },
    {
      label: "Contact",
      slug: "/contact",
    },
    {
      label: "Support Me",
      slug: "/shop",
    },
  ],
  footer: [
    {
      label: "Home",
      slug: "/",
    },
    {
      label: "Artwork",
      slug: "https://azamraharjo.com",
    },
    {
      label: "About",
      slug: "/about",
    },
    {
      label: "Contact",
      slug: "/contact",
    },
    {
      label: "Support Me",
      slug: "/shop",
    },
  ],
}

export default nav
