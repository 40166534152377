import { lighten } from "polished"

// THEME CONFIGURATION

// =================
// usage
// =================
/*
// in styled-component (no styled-system)
import theme from '../../config/theme';
const Foo = styled.div`background-color: ${theme.colors.brand};`

// in styled-component (with styled-system)
import { color } from 'styled-system'; // see full API below
const Foo = styled.div`${color}`
Foo.defaultProps = { bg: 'green' } 
render(<Foo bg="brand" />)
*/
// === end ===

// make modular font sizes array
// https://polished.js.org/docs/#modularscale
// scale ratio "major second": https://www.modularscale.com/?16&px&1.125
import { modularScale } from "polished"
let fontSizes = []
for (let i = 0; i < 20; i++) {
  fontSizes[i] = modularScale(i - 2, "1rem", "majorSecond")
}

const config = require("./site")

const theme = {
  // The `theme` object is passed to `Layout` through styled-componentsʼ
  // `ThemeProvider`: https://www.styled-components.com/docs/advanced#theming

  // We can use it as a regular object (eg. as CSS value), but it is
  // also used by styled-system to override its own default theme values.
  // https://github.com/jxnblk/styled-system/blob/master/docs/api.md
  // https://github.com/jxnblk/styled-system/blob/master/docs/table.md

  // To override, make an object with same name as "Theme Field" in table above
  // (eg. Override values for `color` function/prop with `colors` object)

  // ◼
  // default breakpoints = [ '40em', '52em', '64em' ]
  breakpoints: [544, 768, 992, 1200, 1440],

  // ◼
  // import { space } from 'styled-system'
  // default spaces: [ 0, 4, 8, 16, 32, 64, 128, 256, 512 ]
  // (this project uses default)

  // ◼
  // import { fontSize } from 'styled-system'
  // default fontSizes: [ 12, 14, 16, 20, 24, 32, 48, 64, 72 ]
  fontSizes: fontSizes,

  // ◼
  // import { color } from 'styled-system'
  colors: {
    bg: config.backgroundColor || "#E6E6E6",
    fg: config.textColor || "rgba(0,0,0,0.8)",
    cardBg: config.cardBackgroundColor || "#ffffff",
    accent: config.accentColor || "cyan",
    secondary: config.secondaryColor || "hsla(0, 0%, 10%, 0.125)",

    fgMuted: [
      lighten(0.125, `${config.textColor || "rgba(0,0,0,0.8)"}`),
      lighten(0.25, `${config.textColor || "rgba(0,0,0,0.8)"}`),
      lighten(0.375, `${config.textColor || "rgba(0,0,0,0.8)"}`),
      lighten(0.5, `${config.textColor || "rgba(0,0,0,0.8)"}`),
      lighten(0.625, `${config.textColor || "rgba(0,0,0,0.8)"}`),
      lighten(0.75, `${config.textColor || "rgba(0,0,0,0.8)"}`),
      lighten(0.875, `${config.textColor || "rgba(0,0,0,0.8)"}`),
    ],

    darkMode: {
      white: "#fff",
      black: "#000",
    },
  },

  // Other functions (`fontFamily`, `lineHeight`, etc) have same usage
  // as core functions above, eg.
  // import { borderRadius }  from "styled-system"
  // export const Foo = styled.div`${borderRadius}`

  lineHeights: [1, 1.125, 1.25, 1.375, 1.5, 1.6],

  fonts: {
    sans: `Questrial, sans-serif`,
    serif: `Times, Georgia, serif`,
    heading: `"Barlow Semi Condensed", sans-serif`,
  },

  fontWeights: {
    body: "400",
    head: "500",
  },

  borders: ["0.125rem", "0.25rem", "0.5rem"],

  // "Variants" are used to define sets of reusable custom styles
  // https://github.com/jxnblk/styled-system/blob/master/docs/api.md#variants

  textStyles: {},

  colorStyles: {},

  buttons: {},

  // custom

  thumbnailMaxWidth: "32rem",
  containerMaxWidth: "48rem",
}
export default theme

///

// let darkTheme = theme
// darkTheme.colors = {
//   fg: 'hsla(0, 0%, 100%, 1)',
//   bg: 'hsla(0, 0%, 10%, 1)',
// }
// export { darkTheme }

export const darkColors = {
  fg: "#fff",
  bg: "#000",
}

///

let darkTheme = {}
darkTheme.colors = darkColors
export { darkTheme }

///
