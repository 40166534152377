import React, { Component } from "react"
import siteData from "../../../config/site"
import nav from "../../../config/nav"
import { FooterWrapper, FooterNavContainer, FooterListItem, Copyright } from "./Footer.css"
import { Link } from "gatsby"
///

class Footer extends Component {
  render() {
    return (
      <FooterWrapper role="contentinfo">
        <FooterNavContainer>
          <ul>
            {nav.footer.map(item => (
              <FooterListItem key={item.slug}>
                {item.slug.startsWith("http") ? (
                  <a href={item.slug}>{item.label}</a>
                ) : (
                  <Link to={item.slug}>{item.label}</Link>
                )}
              </FooterListItem>
            ))}
          </ul>
          <a href={`mailto:${siteData.email}`}>{siteData.emailLabel}</a>
        </FooterNavContainer>
        <Copyright>
          {/* {new Date().getFullYear()} */}
          All contents are copyright of Azam Raharjo unless otherwise noted. Do not use without
          permission.
        </Copyright>
      </FooterWrapper>
    )
  }
}

export default Footer
