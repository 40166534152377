import styled from "styled-components"
import { space } from "styled-system"
import { outerContainer } from "../../../config/mixins"
import theme from "../../../config/theme"
import { transparentize } from "polished"

///

export const LayoutWrapper = styled.div`
  ${space}

  min-height: 40rem; // fallback
  min-height: calc(100vh - 16rem);

  // not working in GlobalStyle
  ::selection {
    background-color: ${transparentize(0.75, theme.colors.accent)};
  }

  .AcLayout__outer-container {
    ${outerContainer()}
  }
`
LayoutWrapper.defaultProps = {
  py: [3],
}

///

export const SkipNav = styled.a.attrs({ className: "show-on-focus" })`
  ${space}

  color: ${theme.colors.bg};
  background-color: ${theme.colors.fg};
  top: 0;
  left: 0;
  text-decoration: none;
`
SkipNav.defaultProps = {
  px: 3,
  py: 3,
}
