import React, { Component } from "react"
import siteData from "../../../config/site"
import nav from "../../../config/nav"
import { Link } from "gatsby"
import VisuallyHidden from "@reach/visually-hidden"
import {
  HeaderWrapper,
  SiteTitleLarge,
  SiteTitleSmall,
  HeaderNav,
  HeaderListItem,
  SocialLink,
  SocialIcon,
} from "./Header.css"

///

const SocialLinkItem = ({ url, children }) => (
  <HeaderListItem className="social-icon">
    <SocialLink href={url}>{children}</SocialLink>
  </HeaderListItem>
)

class Header extends Component {
  render() {
    let facebook
    let instagram
    let twitter
    let tumblr
    let patreon
    let behance

    if (siteData.social.facebook) {
      facebook = (
        <SocialLinkItem url={`https://facebook.com/${siteData.social.facebook}`}>
          <SocialIcon viewBox="0 0 96.124 96.123">
            <path d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803 c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654 c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246 c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z" />
          </SocialIcon>
          <VisuallyHidden>Facebook</VisuallyHidden>
        </SocialLinkItem>
      )
    }

    if (siteData.social.instagram) {
      instagram = (
        <SocialLinkItem url={`https://instagram.com/${siteData.social.instagram}`}>
          <SocialIcon viewBox="0 0 24 28">
            <path d="M16 14c0-2.203-1.797-4-4-4s-4 1.797-4 4 1.797 4 4 4 4-1.797 4-4zM18.156 14c0 3.406-2.75 6.156-6.156 6.156s-6.156-2.75-6.156-6.156 2.75-6.156 6.156-6.156 6.156 2.75 6.156 6.156zM19.844 7.594c0 0.797-0.641 1.437-1.437 1.437s-1.437-0.641-1.437-1.437 0.641-1.437 1.437-1.437 1.437 0.641 1.437 1.437zM12 4.156c-1.75 0-5.5-0.141-7.078 0.484-0.547 0.219-0.953 0.484-1.375 0.906s-0.688 0.828-0.906 1.375c-0.625 1.578-0.484 5.328-0.484 7.078s-0.141 5.5 0.484 7.078c0.219 0.547 0.484 0.953 0.906 1.375s0.828 0.688 1.375 0.906c1.578 0.625 5.328 0.484 7.078 0.484s5.5 0.141 7.078-0.484c0.547-0.219 0.953-0.484 1.375-0.906s0.688-0.828 0.906-1.375c0.625-1.578 0.484-5.328 0.484-7.078s0.141-5.5-0.484-7.078c-0.219-0.547-0.484-0.953-0.906-1.375s-0.828-0.688-1.375-0.906c-1.578-0.625-5.328-0.484-7.078-0.484zM24 14c0 1.656 0.016 3.297-0.078 4.953-0.094 1.922-0.531 3.625-1.937 5.031s-3.109 1.844-5.031 1.937c-1.656 0.094-3.297 0.078-4.953 0.078s-3.297 0.016-4.953-0.078c-1.922-0.094-3.625-0.531-5.031-1.937s-1.844-3.109-1.937-5.031c-0.094-1.656-0.078-3.297-0.078-4.953s-0.016-3.297 0.078-4.953c0.094-1.922 0.531-3.625 1.937-5.031s3.109-1.844 5.031-1.937c1.656-0.094 3.297-0.078 4.953-0.078s3.297-0.016 4.953 0.078c1.922 0.094 3.625 0.531 5.031 1.937s1.844 3.109 1.937 5.031c0.094 1.656 0.078 3.297 0.078 4.953z" />
          </SocialIcon>
          <VisuallyHidden>Instagram</VisuallyHidden>
        </SocialLinkItem>
      )
    }

    if (siteData.social.twitter) {
      twitter = (
        <SocialLinkItem url={`https://twitter.com/${siteData.social.twitter}`}>
          <SocialIcon viewBox="0 0 273.4 222.2">
            <path d="M273.4,26.3c-10.1,4.5-20.9,7.5-32.2,8.8c11.6-6.9,20.5-17.9,24.7-31C255,10.5,243,15.2,230.2,17.7 C220,6.8,205.4,0,189.3,0c-31,0-56.1,25.1-56.1,56.1c0,4.4,0.5,8.7,1.5,12.8C88,66.5,46.7,44.2,19,10.3c-4.8,8.3-7.6,17.9-7.6,28.2 c0,19.5,9.9,36.6,25,46.7c-9.2-0.3-17.8-2.8-25.4-7c0,0.2,0,0.5,0,0.7c0,27.2,19.3,49.8,45,55c-4.7,1.3-9.7,2-14.8,2 c-3.6,0-7.1-0.4-10.6-1c7.1,22.3,27.9,38.5,52.4,39c-19.2,15-43.4,24-69.7,24c-4.5,0-9-0.3-13.4-0.8c24.8,15.9,54.3,25.2,86,25.2 c103.2,0,159.6-85.5,159.6-159.6c0-2.4-0.1-4.9-0.2-7.3C256.4,47.4,265.9,37.5,273.4,26.3z" />
          </SocialIcon>
          <VisuallyHidden>Twitter</VisuallyHidden>
        </SocialLinkItem>
      )
    }

    if (siteData.social.tumblr) {
      tumblr = (
        <SocialLinkItem url={`https://${siteData.social.tumblr}.tumblr.com`}>
          <SocialIcon viewBox="0 0 533.333 533.333">
            <path d="M444.469,0H88.9C40.004,0,0,40.007,0,88.899v355.535c0,48.926,40.004,88.899,88.899,88.899h355.569 c48.891,0,88.864-39.974,88.864-88.899V88.898C533.329,40.007,493.355,0,444.469,0z M381.103,429.524 c-15.705,7.39-29.965,12.598-42.709,15.593c-12.758,2.965-26.542,4.458-41.355,4.458c-16.829,0-26.758-2.114-39.681-6.348 c-12.92-4.265-23.958-10.352-33.073-18.164c-9.145-7.878-15.461-16.249-18.993-25.101c-3.534-8.856-5.29-21.715-5.29-38.544 V232.356h-49.998v-52.118c14.45-4.688,31.217-11.424,41.502-20.182c10.333-8.788,18.603-19.301,24.835-31.606 c6.252-12.274,10.547-27.93,12.892-46.91h52.343v85.126h85.09v65.691h-85.092v94.369c0,21.354-0.275,33.659,1.986,39.713 c2.246,6.021,7.879,12.271,14.012,15.886c8.154,4.884,17.45,7.323,27.931,7.323c18.639,0,37.175-6.056,55.6-18.165V429.524 L381.103,429.524z" />
          </SocialIcon>
          <VisuallyHidden>Tumblr</VisuallyHidden>
        </SocialLinkItem>
      )
    }

    if (siteData.social.patreon) {
      patreon = (
        <SocialLinkItem url={`https://patreon.com/${siteData.social.patreon}`}>
          <SocialIcon viewBox="0 0 180 180">
            <path d="M108.8135992 26.06720125c-26.468266 0-48.00213212 21.53066613-48.00213212 47.99733213 0 26.38653268 21.53386613 47.85426547 48.00213213 47.85426547 26.38639937 0 47.8530655-21.4677328 47.8530655-47.85426547 0-26.466666-21.46666613-47.99733213-47.85306547-47.99733213" />
            <path d="M23.333335 153.93333178V26.0666679h23.46666576v127.8666639z" />
          </SocialIcon>
          <VisuallyHidden>Patreon</VisuallyHidden>
        </SocialLinkItem>
      )
    }

    if (siteData.social.behance) {
      behance = (
        <SocialLinkItem url={`https://behance.com/${siteData.social.behance}`}>
          <SocialIcon viewBox="0 0 511.958 511.958">
            <path d="M210.624,240.619c10.624-5.344,18.656-11.296,24.16-17.728c9.792-11.584,14.624-26.944,14.624-45.984 c0-18.528-4.832-34.368-14.496-47.648c-16.128-21.632-43.424-32.704-82.016-33.28H0v312.096h142.56 c16.064,0,30.944-1.376,44.704-4.192c13.76-2.848,25.664-8.064,35.744-15.68c8.96-6.624,16.448-14.848,22.4-24.544 c9.408-14.656,14.112-31.264,14.112-49.76c0-17.92-4.128-33.184-12.32-45.728C238.912,255.627,226.752,246.443,210.624,240.619z M63.072,150.187h68.864c15.136,0,27.616,1.632,37.408,4.864c11.328,4.704,16.992,14.272,16.992,28.864 c0,13.088-4.32,22.24-12.864,27.392c-8.608,5.152-19.776,7.744-33.472,7.744H63.072V150.187z M171.968,348.427 c-7.616,3.68-18.336,5.504-32.064,5.504H63.072v-83.232h77.888c13.568,0.096,24.128,1.888,31.68,5.248 c13.44,6.08,20.128,17.216,20.128,33.504C192.768,328.651,185.856,341.579,171.968,348.427z" />
            <rect x="327.168" y="110.539" width="135.584" height="38.848" />
            <path d="M509.856,263.851c-2.816-18.08-9.024-33.984-18.688-47.712c-10.592-15.552-24.032-26.944-40.384-34.144 c-16.288-7.232-34.624-10.848-55.04-10.816c-34.272,0-62.112,10.72-83.648,32c-21.472,21.344-32.224,52.032-32.224,92.032 c0,42.656,11.872,73.472,35.744,92.384c23.776,18.944,51.232,28.384,82.4,28.384c37.728,0,67.072-11.232,88.032-33.632 c13.408-14.144,20.992-28.064,22.656-41.728H446.24c-3.616,6.752-7.808,12.032-12.608,15.872 c-8.704,7.04-20.032,10.56-33.92,10.56c-13.216,0-24.416-2.912-33.76-8.704c-15.424-9.28-23.488-25.536-24.512-48.672h170.464 C512.16,289.739,511.52,274.411,509.856,263.851z M342.976,269.835c2.24-15.008,7.68-26.912,16.32-35.712 c8.64-8.768,20.864-13.184,36.512-13.216c14.432,0,26.496,4.128,36.32,12.416c9.696,8.352,15.168,20.48,16.288,36.512H342.976z" />
          </SocialIcon>
          <VisuallyHidden>Behance</VisuallyHidden>
        </SocialLinkItem>
      )
    }

    return (
      <HeaderWrapper role="banner">
        <Link to="/">
          <h1>
            <SiteTitleSmall>
              {` `}Comics by{` `}
            </SiteTitleSmall>
            <SiteTitleLarge>Azam Raharjo</SiteTitleLarge>
          </h1>
        </Link>

        <HeaderNav>
          {nav.header.map(item => (
            <HeaderListItem key={item.slug}>
              {item.slug.startsWith("http") ? (
                <a href={item.slug}>{item.label}</a>
              ) : (
                <Link to={item.slug} activeClassName="is-active">
                  {item.label}
                </Link>
              )}
            </HeaderListItem>
          ))}
        </HeaderNav>

        <ul>
          {facebook && facebook}
          {instagram && instagram}
          {twitter && twitter}
          {tumblr && tumblr}
          {patreon && patreon}
          {behance && behance}
        </ul>
      </HeaderWrapper>
    )
  }
}

export default Header
