import PropTypes from "prop-types"
import React from "react"
import { ThemeProvider } from "styled-components"
import theme from "../../../config/theme"
import Header from "../Header"
import Footer from "../Footer"
import VisuallyHidden from "@reach/visually-hidden"
import { GlobalStyle } from "../GlobalStyle"
import { LayoutWrapper, SkipNav } from "./Layout.css.js"

///

// https://reactjs.org/docs/context.html#api
export const ThemeContext = React.createContext("light")

class Layout extends React.Component {
  render() {
    const { children, activeTheme, showHeader } = this.props
    // const rootPath = `${__PATH_PREFIX__}/`
    // customize header (different header in Home vs non-Home)
    // (not used)
    // let header
    // if (location.pathname === rootPath) {
    //   header = (<h1><Link to={`/`}>{title}</Link></h1>)
    // } else {
    //   header = <Link to={`/`}>{title}</Link>
    // }
    // const header = <Header />

    return (
      <ThemeProvider theme={theme}>
        <ThemeContext.Provider value={activeTheme}>
          <React.Fragment>
            <GlobalStyle activeTheme={activeTheme} />
            <SkipNav href="#content">Skip to content</SkipNav>
            {showHeader ? (
              <Header />
            ) : (
              <VisuallyHidden>
                <h1>Comics by Azam Raharjo</h1>
              </VisuallyHidden>
            )}
            <LayoutWrapper as="main" id="content">
              {children}
            </LayoutWrapper>
            <Footer />
          </React.Fragment>
        </ThemeContext.Provider>
      </ThemeProvider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  // extraNav: PropTypes.node, // eg. TopMenu in SingleComic template
  // title: PropTypes.string, // for customized header — currently not used
  // location: PropTypes.string, // for customized header — currently not used
  showHeader: PropTypes.bool,
  activeTheme: PropTypes.oneOf(["light", "dark"]),
}

Layout.defaultProps = {
  showHeader: true,
}

export default Layout
