module.exports = {
  // Your site title. Recommended 40-80 chars.
  siteTitle: "Comics by Azam Raharjo",
  // Your site description. Recommended 70-280 chars.
  siteDescription:
    "Read webcomics by Azam Raharjo",
  // Your site domain (this generally should not be changed). No slash at the end.
  siteUrl: "https://comics.azamraharjo.com",
  // Your site default language.
  siteLanguage: "en",
  // Default image, used for social media share thumbnail. Recommended 600 x 300px.
  siteLogo: "social-thumb.png",
  // Author name, used for search engine results.
  author: "Azam Raharjo",
  // Email, displayed in site footer.
  email: "azam@azamraharjo.com",
  emailLabel: "azam [@] azamraharjo.com",

  // Social accounts username
  social: {
    twitter: "AzamRaharjo",
    instagram: "AzamRaharjo",
    patreon: "AzamRaharjo",
  },

  // Colors

  // Warna teks
  textColor: "rgba(0,0,0,0.8)",
  // Warna latar halaman
  backgroundColor: "#E6E6E6",
  // Warna latar card
  cardBackgroundColor: "#ffffff",
  // Aksen utama: warna mouseover seluruh header, footer, text link; warna border thumbnail episode aktif di bagian bawah halaman Comic Content (disarankan cenderung gelap)
  accentColor: "#666",
  // Aksen latar: warna mouseover button (disarankan cenderung terang)
  secondaryColor: "#ddd",
  // Warna toolbar jika diakses di Chrome Mobile
  chromeMobileColor: "#000000",
}
